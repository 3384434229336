.header {
  height: 10vh;
  background-color: #7f1716;
  max-width: 100vw;
  padding: 0 8%;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
}

@media (max-width: 470px) {
  .header-title {
    font-size: 0.85rem !important;
    text-align: left;
  }
}
